import React, { useEffect, useState } from 'react';
import '../styles/main.css';
import Header from './header';
import Slider from './slider';
import Card from './card';
import photo1 from '../image/search.png'
import photo2 from '../image/house.png'
import photo3 from '../image/cash.png'
import Footer from './footer';
import Subscription from './subscription';
import FastSearch from './fastSearch';

const Main = () => {

  let [Cards, setCards] = useState([]);

  const load = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://pets.сделай.site/api/pets", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.data.orders.length > 0) {
          setCards(result.data.orders);
        }
      })
      .catch(error => console.log('error', error));

  }

  useEffect(load, [])

  return (
    <div>

      <Header />

      <h1 className="title">Найденные животные</h1>

      <Slider />

      <h1 className="titleOne">Карточки найденных животных</h1>
      <div className="cards ">
        {Cards.map((item, index) => <Card data={item} key={index}/>)}
      </div>

      <FastSearch/>

      <h1 className="titleOne">Факты о компании</h1>

      <div style={{ "margin": "20px 20% 20px 20%" }}>
        <p className="fs-4"><img style={{ "width": "40px" }} src={photo1} alt="Картинка" /> Помогли найти более 500 животных</p>
        <p className="fs-4"><img style={{ "width": "40px" }} src={photo2} alt="Картинка" /> Более трех лет способствуем возвращению питомцев к хозяевам</p>
        <p className="fs-4"><img style={{ "width": "40px" }} src={photo3} alt="Картинка" /> Все услуги оказываются бесплатно</p>
      </div>
      <h1 className="titleOne">Подписка на новости</h1>
      <Subscription />
      <Footer />
    </div>
  );
};

export default Main;