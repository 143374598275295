import React, { useEffect, useRef, useState } from 'react';
import '../styles/main.css';
import Header from './header';
import Footer from './footer';
import Form from './form';
import CardAccount from './cardAccount';

const Account = () => {
    let [Cards, setCards] = useState([]);
    let block = useRef();

    const load = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://pets.сделай.site/api/users/orders", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.data.orders.length > 0) {
                    setCards(result.data.orders);
                }
                else {
                    let message = 'У вас нет объявлений';
                    block.current.innerText = message;
                    block.current.style.background = "#D6D6FF"
                    block.current.style.color = "black";
                    block.current.style.border = "1px solid rgb(119, 119, 255)"
                    block.current.style.display = 'block';
                }
            })
            .catch(error => console.log('error', error));
    }
    useEffect(load, [])
    return (
        <div>
            <Header />
            <h1 className="title">Личный кабинет</h1>

            <Form />

            <h1 className="title">Личные объявления</h1>

            <div className="cards">
                {Cards.map((item, index) => <CardAccount data={item} key={index}/>)}
                <div className="alert alert-primary w-50 none nocards" role="alert" ref={block}></div>
            </div>

            <Footer />

        </div>
    );
};

export default Account;