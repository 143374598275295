import React, { useRef, useState } from 'react';
import '../styles/main.css';

const Subscription = () => {
  let [mail, Setmail] = useState('');

  let block = useRef();

  const send = (e) => {
    e.preventDefault();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(mail);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://pets.сделай.site/api/subscription", requestOptions)
      .then(response => response.status)
      .then(result => {
        console.log(result)

        if (result == 204) {
          
          let message = 'Вы успешно подписались на новости';
          block.current.innerText = message;
          block.current.style.background = "#D6D6FF"
          block.current.style.color = "black";
          block.current.style.border = "1px solid rgb(119, 119, 255)"
          block.current.style.display = 'block';
        }
        else {
          
          let message = 'Проверьте правильность ввода email';
          block.current.innerText = message;
          block.current.style.background = '#FFC6C2';
          block.current.style.color = "black";
          block.current.style.border = "1px solid #FF968F"
          block.current.style.display = 'block';
        }
      })
      .catch(error => console.log('error', error));
  }
  return (
    <div>
      <form onSubmit={send} noValidate>
        <div className="subscription">
          <label htmlFor="inputPassword5" className="form-label">Введите адрес электронной почты</label>
          <input pattern="[a-zA-Z0-9]*+@+[a-z]+.+[a-z]{2,}" type="email" id="inputPassword5" className="form-control input" placeholder=" " aria-describedby="passwordHelpBlock" onChange={(e) => Setmail({ ...mail, email: e.target.value })} />
          <div id="passwordHelpBlock" className="form-text">Мы никогда не делимся Вашими e-mail ни с кем</div>
          <button type="submit" className="btn btn-primary subscribe">Подписаться</button>
        </div>
      </form>
      <div className="alert alert-primary w-50 none anim_mess" role="alert" ref={block}>
      </div>
    </div>
  );
};

export default Subscription;