import '../styles/main.css';
import Header from './header';
import Footer from './footer';
import { useRef } from 'react';

const AddAnnouncement = () => {

    let block = useRef();

    const add = (e) => {

        e.preventDefault();

        const forms = document.getElementById('form')

        if (!forms.checkValidity()) {
            e.stopPropagation()
            forms.classList.add('was-validated')
            return
        }

        var myHeaders = new Headers();
        var formdata = new FormData(document.getElementById('form'));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://pets.сделай.site/api/pets", requestOptions)
            .then(response => response.json())
            .then(result => {
                if ('data' in result)
                {
                    let message = 'Вы успешно доавили объявление!';
					block.current.innerText = message;
					block.current.style.background = "#D6D6FF"
					block.current.style.color = "black";
					block.current.style.border = "1px solid rgb(119, 119, 255)"
					block.current.style.display = 'block';
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <div>
            <Header />

            <h1 className="title">Добавить объявление</h1>

            <form className="needs-validation w-50 flex justify-content-center" noValidate style={{ "margin": "30px auto 0 auto", "minWidth": "300px" }} onSubmit={add} id='form'>
                <div style={{ "margin": "15px auto 0px auto" }}>
                    <label htmlFor="validationCustom01" className="form-label">Имя</label>
                    <input name="name" type="text" pattern='^[А-Яа-яёЁ\s\-]+$' className="form-control" id="validationCustom01" required />
                    <div className="invalid-feedback anim_mess">
                        Введите имя, используйте кириллицу, пробел, тире
                    </div>
                </div>
                <div style={{ "margin": "15px auto 0px auto" }}>
                    <label htmlFor="validationCustom02" className="form-label">Телефон</label>
                    <input name="phone" type="text" pattern='^[\d\+]{7,}$' className="form-control" id="validationCustom02" required/>
                    <div className="invalid-feedback anim_mess">
                        Введите номер телефона, используйте + и цифры
                    </div>
                </div>
                <div style={{ "margin": "15px auto 15px auto" }}>
                    <label htmlFor="validationCustom03" className="form-label">Email</label>
                    <input name="email" type="email" className="form-control" id="validationCustom03" required/>
                    <div className="invalid-feedback anim_mess">
                        Укажите корректный email
                    </div>
                </div>
                <div style={{ "margin": "15px auto 15px auto" }}>
                    <label id="validationCustom04" className="form-label">Фотография 1</label>
                    <input accept="image/png" name="photos1" type="file" className="form-control" required/>
                </div>
                <div style={{ "margin": "15px auto 15px auto" }}>
                    <label id="validationCustom05" className="form-label">Фотография 2</label>
                    <input accept="image/png" name="photos2" type="file" className="form-control"/>
                </div>
                <div style={{ "margin": "15px auto 15px auto" }}>
                    <label id="validationCustom06" className="form-label">Фотография 3</label>
                    <input accept="image/png" name="photos3" type="file" className="form-control"/>
                </div>
                <div style={{ "margin": "15px auto 0px auto" }}>
                    <label htmlFor="validationCustom10" className="form-label">Вид животного</label>
                    <input name="kind" type="text" className="form-control" id="validationCustom10" required/>
                    <div className="invalid-feedback anim_mess">
                        Введите вид животного
                    </div>
                </div>
                <div style={{ "margin": "15px auto 0px auto" }}>
                    <label htmlFor="validationCustom09" className="form-label">Клеймо</label>
                    <input name="mark" type="text" className="form-control" id="validationCustom09"/>
                    <div className="invalid-feedback anim_mess">
                        Введите клеймо
                    </div>
                </div>
                <div style={{ "margin": "15px auto 0px auto" }}>
                    <label htmlFor="validationCustom11" className="form-label">Район</label>
                    <input name='district' type="text" className="form-control" id="validationCustom11" required/>
                    <div className="invalid-feedback anim_mess">
                        Введите район
                    </div>
                </div>
                <div style={{ "margin": "15px auto 15px auto" }}>
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Описание</label>
                    <textarea name="description" className="form-control" id="exampleFormControlTextarea1" rows="3" required></textarea>
                    <div className="invalid-feedback anim_mess">
                        Опишите животного
                    </div>
                </div>

                <details className="w-100 d-flex flex-column justify-content-center align-items-center">
                    <summary className="btn btn-primary d-flex flex-column justify-content-center align-items-center" style={{ "margin": "auto", "marginBottom": "20px" }}>
                        Зарегистрироваться
                    </summary>
                    <div>
                        <div style={{ "margin": "15px auto 15px auto" }}>
                            <label htmlFor="validationCustom07" className="form-label">Пароль</label>
                            <input name="password" type="password" pattern='^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d]{7,}$' className="form-control" id="validationCustom07" required/>
                            <div className="invalid-feedback anim_mess">
                                Введите пароль, латинские буквы обязательно одна заглавная, строчная и цифра, минимум 7 символов
                            </div>
                        </div>
                        <div style={{ "margin": "15px auto 15px auto" }}>
                            <label htmlFor="validationCustom08" className="form-label">Введите пароль повторно</label>
                            <input name='password_confirmation' type="password" className="form-control" id="validationCustom08" required/>
                            <div className="invalid-feedback anim_mess">
                                Введите пароль повторно, пароли должны совпадать
                            </div>
                        </div>
                    </div>
                </details>
                <div>
                    <div className="form-check" style={{ "margin": "15px auto 15px auto" }}>
                        <input name='confirm' className="form-check-input" type="checkbox" id="invalidCheck" required/>
                        <label className="form-check-label" htmlFor="invalidCheck">
                            Я принимаю оферту и соглашаюсь на обработку персональных данных
                        </label>
                        <div className="invalid-feedback anim_mess">
                            Вы должны принять перед отправкой
                        </div>
                    </div>
                </div>
                <button type="submit" style={{ "margin": "0px auto 15px auto" }} className="btn btn-primary">Добавить</button>
            </form>
            <div className="alert alert-primary w-50 none anim_mess" role="alert" ref={block}></div>
            <Footer />

        </div>
    );
};

export default AddAnnouncement;