import React from 'react';
import logo from '../image/logo.png';
import '../styles/main.css';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light menu" style = {{"paddingRight" : "10%",	"paddingLeft" : "10%"}}>
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 align-items-center">
              <Link to = {'/'} style={{"width": "30%"}}><img style={{"width": "35%"}} src={logo} /></Link>
              <li className="nav-item"><Link to = {'/'} className="nav-link ln">Главная</Link></li>
              <li className="nav-item"><Link to = {'/search'} className="nav-link ln">Поиск по объявлениям</Link></li>
              <li className="nav-item"><Link to = {'/registration'} className="nav-link ln">Регистрация</Link></li>
              <li className="nav-item"><Link to = {'/sign_in'} className="nav-link ln">Вход</Link></li>
              <li className="nav-item"><Link to = {'/account'} className="nav-link ln">Личный кабинет</Link></li>
              <li className="nav-item"><Link to = {'/add_announcement'} className="nav-link ln">Добавить объявление</Link></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;