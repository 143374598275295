import '../styles/main.css';

const CardAccount = (props) => {
    const del = (id) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.token);

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`https://pets.сделай.site/api/users/orders/${id}`, requestOptions)
            .then(response => response.status)
            .then(result => {
                window.location.reload();
            })
            .catch(error => console.log('error', error));
    }

    function update(e) {
        e.preventDefault();

        const forms = document.getElementById("form" + props.data.id);

        if (!forms.checkValidity()) {
            e.stopPropagation()
            forms.classList.add('was-validated')
            return
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.token);

        var formdata = new FormData(document.getElementById('form' + props.data.id));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://pets.сделай.site/api/pets/" + props.data.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                if ('data' in result)
                {
                    window.location.reload();
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <div className="cards">
            <div className="card" style={{ "width": "24rem" }}>
                <img src={'https://pets.сделай.site' + props.data.photos} className="card-img-top imageAccount" alt="Картинка жук скоробей" />
                <div className="card-body d-flex flex-column justify-content-start">
                    <h5>{props.data.kind}</h5>
                    <p className="card-text">Описание: {props.data.description}</p>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Код: {props.data.mark}</li>
                    <li className="list-group-item">Район: {props.data.district}</li>
                    <li className="list-group-item">Дата: {props.data.date}</li>
                    <li className="list-group-item">Статус объявления: {props.data.status}</li>
                    <li className="list-group-item">
                        <div className="d-flex flex-raw justify-content-between align-items-center p-1">
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#upd" + props.data.id}>Редактировать</button>
                            <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target={"#del" + props.data.id}>Удалить пост</button>
                        </div>
                    </li>
                </ul>

                <div className="modal anim_mess" id={"del" + props.data.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Вы действительно хотите удалить пост?</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body d-flex justify-content-center">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => del(props.data.id)}>Удалить</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal anim_mess" id={"upd" + props.data.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Редактировать номер телефона</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form className="needs-validation" noValidate id={"form" + props.data.id} onSubmit={update}>
                                    <div style={{ "marginTop": "15px" }}>
                                        <label htmlFor="validationCustom10" className="form-label">Вид животного</label>
                                        <input name="kind" type="text" className="form-control" id="validationCustom10" required />
                                        <div className="invalid-feedback anim_mess">
                                            Введите вид животного
                                        </div>
                                    </div>
                                    <div style={{ "marginTop": "15px" }}>
                                        <label id="validationCustom04" className="form-label">Фотография 1</label>
                                        <input name="photos1" type="file" className="form-control" required />
                                    </div>
                                    <div style={{ "marginTop": "15px" }}>
                                        <label id="validationCustom05" className="form-label">Фотография 2</label>
                                        <input name="photos2" type="file" className="form-control" />
                                    </div>
                                    <div style={{ "marginTop": "15px" }}>
                                        <label id="validationCustom06" className="form-label">Фотография 3</label>
                                        <input name="photos3" type="file" className="form-control" />
                                    </div>

                                    <div style={{ "marginTop": "15px" }}>
                                        <label htmlFor="validationCustom09" className="form-label">Клеймо</label>
                                        <input name="mark" type="text" className="form-control" id="validationCustom09" />
                                        <div className="invalid-feedback anim_mess">
                                            Введите клеймо
                                        </div>
                                    </div>
                                    <div style={{ "marginTop": "15px" }}>
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Описание</label>
                                        <textarea name="description" className="form-control" id="exampleFormControlTextarea1" rows="3" required></textarea>
                                        <div className="invalid-feedback anim_mess">
                                            Опишите животного
                                        </div>
                                    </div>
                                    <div className="form-check" style={{ "marginTop": "15px" }}>
                                        <input name='confirm' className="form-check-input" type="checkbox" id="invalidCheck" required />
                                        <label className="form-check-label" htmlFor="invalidCheck">
                                            Я принимаю оферту и соглашаюсь на обработку персональных данных
                                        </label>
                                        <div className="invalid-feedback anim_mess">
                                            Вы должны принять перед отправкой
                                        </div>
                                    </div>
                                    <button type="submit" style={{ "marginTop": "15px" }} className="btn btn-primary">Редактировать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default CardAccount;