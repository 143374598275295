import React, { useRef, useState } from 'react';
import '../styles/main.css';
import Header from './header';
import Footer from './footer';
import Card from './card';

const Search = () => {

  let [request, setRequest] = useState({});
  let [pag, setPag] = useState([])
  let [but, setBut] = useState([])

  let block = useRef();
  let cards = useRef();

  function find(e) {
    e.preventDefault();

    const forms = document.getElementById('form')

    if (!forms.checkValidity()) {
      e.stopPropagation()
      forms.classList.add('was-validated')
      return
    }

    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://pets.сделай.site/api/search/order?district=" + request.district + " &kind=" + request.kind, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.data.orders.length > 0) {
          setPag(result.data.orders.slice(0, 10));
          let a = [];
          for (let i = 0; i < result.data.orders.length; i += 10) {
            a.push(<li className="page-item" key={i + 'list'}><a className="page-link"
              onClick={() => setPag(result.data.orders.slice(i, i + 10))}>{(i / 10 + 1)}</a></li>)
            setBut(a)
          }

          cards.current.style.display = 'flex';
          block.current.style.display = 'none';
        }
        else {
          cards.current.style.display = 'none';
          let message = 'Не найдено';
          block.current.innerText = message;
          block.current.style.background = "#D6D6FF"
          block.current.style.color = "black";
          block.current.style.border = "1px solid rgb(119, 119, 255)"
          block.current.style.display = 'block';
        }
      })
      .catch(error => console.log('error', error));
  }


  return (


    <div>
      <Header />
      <h1 className="title">Поиск по объявлениям</h1>

      <form className="needs-validation w-50 flex justify-content-center" noValidate style={{ "margin": "30px auto 0 auto", "minWidth": "300px" }} onSubmit={find} id='form'>
        <div>
          <label htmlFor="validationCustom01" className="form-label">Район</label>
          <input type="text" className="form-control" id="validationCustom01" required onChange={(e) => setRequest({ ...request, district: e.target.value })} />
          <div className="invalid-feedback anim_mess">
            Введите район
          </div>
        </div>
        <div style={{ "margin": "15px auto 0px auto" }}>
          <label htmlFor="validationCustom02" className="form-label">Вид</label>
          <input type="text" className="form-control" id="validationCustom02" required onChange={(e) => setRequest({ ...request, kind: e.target.value })} />
          <div className="invalid-feedback anim_mess">
            Введите вид
          </div>
        </div>
        <div>
          <button className="btn btn-primary" style={{ "marginTop": "15px" }} type="submit">Найти</button>
        </div>
      </form>
      <hr />

      <div className="cards w-100" style={{"minHeight": "33.5vh"}} ref={cards}>
        {pag.map((item, index) => <Card data={item} key={index} />)}
      </div>
      <div className="alert alert-primary w-50 none nocards" role="alert" ref={block}></div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {but}
        </ul>
      </nav>

      <Footer />


    </div>
  );
};

export default Search;