import React from 'react';
import '../styles/main.css';

const Footer = () => {
  return (
    <div>
        <div className = "footer">
		<p>GET PET BACK © Copyright, 2023</p>
		<p>Все права защищены</p>
		</div>
    </div>
  );
};

export default Footer;