import React from 'react';
import '../styles/main.css';
import { useNavigate } from 'react-router-dom';

const Card = (props) => {

  const navigate = useNavigate();

  return (
    <div className="card post" style={{ "width": "18rem" }}>
      <img src={'https://pets.сделай.site' + props.data.photos} className="card-img-top imageCard" alt="Картинка бабочка" />
      <div className="card-body d-flex flex-column justify-content-start">
        <h5>{props.data.kind}</h5>
        <p className="card-text">Описание: {props.data.description}</p>
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">Район: {props.data.district}</li>
        <li className="list-group-item">Дата: {props.data.date}</li>
        <li className="list-group-item"><button onClick={() => {navigate("/cardPage", { state: props.data.id })}} className="btn btn-light w-100">Подробнее</button></li>
      </ul>
    </div>
  );
};

export default Card;