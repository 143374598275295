import React, { useEffect, useState } from 'react';
import '../styles/main.css';

function Slide(props) {
  return (
    <div className={props.css_class}>
      <h2 className='text-center text-success'>{props.data.kind}</h2>
      <p className='text-center'>{props.data.description}</p>
      <img src={'https://pets.сделай.site' + props.data.image} className="d-block m-auto" alt="photo_pets" style={{ height: '400px', paddingBottom: "40px"}} />
    </div>
  )
}

function Loader(props) {
  return (<div className='justify-content-center align-items-center' id='loader' style={props.display}>
    <div className='fs-1 text-success'>...Идет загрузка</div>
  </div>)
}

function Slider() {
  let [slide, setSlide] = useState({ data: { pets: [] } });
  let [show, setShow] = useState({ display: 'flex' });
  useEffect(() => req_slide(slide, setSlide), []);

  function req_slide(slide, setSlide) {
    var requestOptions = {
      method: 'GET',
    };

    fetch("https://pets.сделай.site/api/pets/slider", requestOptions)
      .then(response => response.json())
      .then(result => {
        setSlide(result)
        setShow({ display: 'none' });

      })
      .catch(error => console.log('error', error));

  }
  let slides = slide.data.pets.map((pet, index) => {
    if (index == 0) {
      return <Slide data={pet} key={index} css_class='carousel-item active' />;
    } else {
      return <Slide data={pet} key={index} css_class='carousel-item' />;

    }
  })

  let indicators = slide.data.pets.map((pet, index) => {
    if (index == 0) {
      return <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" key={index + 'btn'}></button>;
    } else {
      return <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} aria-label={"Slide " + (Number(index) + 1)} key={index + 'btn'}></button>;

    }
  })


  return (
    <div>
      <Loader display={show} />
      <div id="carouselExampleIndicators" className="carousel slide m-auto carousel-fade" data-bs-ride="carousel" style={{" minWidth" : "310px",
    "background": "#d9d9d9"}}>
        <div className="carousel-indicators">
          {indicators}
        </div>
        <div className="carousel-inner">

          {slides}

        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>

  )
}

export default Slider;