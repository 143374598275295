import React, { useRef, useState } from 'react';
import '../styles/main.css';
import Header from './header';
import Footer from './footer';
import { useNavigate } from 'react-router-dom';

const Signin = () => {
    let [user, setUser] = useState();
    let [token, setToken] = useState();

    let block = useRef();
    let history = useNavigate();

    function sign(e) {
        e.preventDefault();

        const forms = document.getElementById('form')

        if (!forms.checkValidity()) {
            e.stopPropagation()
            forms.classList.add('was-validated')
            return
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(user);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://pets.сделай.site/api/login", requestOptions)
            .then(response => response.json())
            .then(result => {
                if ('data' in result) {
                    localStorage.token = result.data.token;
                    setToken(result.data.token);
                    history('/account');
                }
                else
                {
                    let message = 'Неправильный адрес электронной почты или пароль';
					block.current.innerText = message;
					block.current.style.background = '#FFC6C2';
					block.current.style.color = "black";
					block.current.style.border = "1px solid #FF968F"
					block.current.style.display = 'block';
                }
            })
            .catch(error => console.log('error', error));
    }
    return (
        <div>
            <Header />
            <div className='main'>
                <h1 className="title">Вход</h1>

                <form className="needs-validation w-50 flex justify-content-center" noValidate style={{ "margin": "30px auto 0 auto", "minWidth": "300px" }} onSubmit={sign} id='form'>
                    <div style={{ "margin": "15px auto 15px auto" }}>
                        <label htmlFor="validationCustom01" className="form-label">Email</label>
                        <input type="email" className="form-control" id="validationCustom01" required onChange={(e) => setUser({ ...user, email: e.target.value })} />
                        <div className="invalid-feedback anim_mess">
                            Укажите корректный email
                        </div>
                    </div>
                    <div style={{ "margin": "15px auto 15px auto" }}>
                        <label htmlFor="validationCustom02" className="form-label">Пароль</label>
                        <input type="password" pattern='^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d]{7,}$' className="form-control" id="validationCustom02" required onChange={(e) => setUser({ ...user, password: e.target.value })} />
                        <div className="invalid-feedback anim_mess">
                            Введите пароль, латинские буквы обязательно одна заглавная, строчная и цифра, минимум 7 символов
                        </div>
                    </div>
                    <div>
                        <button className="btn btn-primary" style={{ "marginBottom": "30px" }} type="submit">Войти</button>
                    </div>
                </form>
                <div className="alert alert-primary w-50 none anim_mess" role="alert" ref={block}></div>
            </div>
            <Footer />

        </div>
    );
};

export default Signin;