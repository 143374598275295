import { useRef, useState } from 'react';
import '../styles/main.css';
import Card from './card';

const FastSearch = () => {

    let [Cards, setCards] = useState([]);
    let block = useRef();
    let res = useRef();
    let but = useRef();
    let list = [];
    let listRes = [];
    let datalist = document.getElementById('datalist');

    const search = (text) => {

        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://pets.сделай.site/api/search?query=" + text, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.data.orders.length > 0) {

                    setCards(result.data.orders);
                    res.current.style.display = "none";

                    if (text.length > 3) {
                        Cards.map((item) => list.push(item.description));
                        listRes = Array.from(new Set(list));
                        datalist.innerHTML = ``;
                        listRes.map((item) => datalist.innerHTML += `<option value="${item}"></option>`);
                    }
                }
                else {
                    let message = 'Не найдено';
                    block.current.style.block = "none";
                    res.current.innerText = message;
                    res.current.style.background = "#D6D6FF"
                    res.current.style.color = "black";
                    res.current.style.border = "1px solid rgb(119, 119, 255)"
                    res.current.style.display = 'block';
                }
            })
            .catch(error => console.log('error', error));
    }

    const visibility = (e) => {
        e.preventDefault();

        if (block.current.style.display == "none") {
            block.current.style.display = "flex";
        }
        else {
            block.current.style.display = "none";
        }
    }

    return (
        <div>
            <h1 className="title">Быстрый поиск по объявлениям</h1>
            <form className="needs-validation w-50 flex justify-content-center" noValidate style={{ "margin": "20px auto 20px auto", "minWidth": "300px" }}>
                <div>
                    <label htmlFor="validationCustom01" className="form-label">Описание животного</label>
                    <div className='d-flex justify-content-center align-items-center gap-3'>
                        <input list='datalist' type="text" className="form-control" id="validationCustom01" required onChange={(e) => setTimeout(() => {search(e.target.value)}, 1000)}/>
                        <datalist id="datalist">
                        </datalist>
                        <button ref={but} className='btn btn-primary' onClick={visibility}>Скрыть</button>
                    </div>
                </div>
            </form>
            <div className="cards" ref={block}>
                {Cards.map((item, index) => <Card data={item} key={index} />)}
            </div>
            <div className="alert alert-primary w-50 none nocards" role="alert" ref={res}></div>
        </div>

    );
};

export default FastSearch;