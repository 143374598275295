import React, { useEffect, useState } from 'react';
import '../styles/main.css';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

const Form = () => {

    let [user, setUser] = useState({});
    let [days, setDays] = useState(0);
    let history = useNavigate();

    const load = () => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://pets.сделай.site/api/users", requestOptions)
            .then(response => response.json())
            .then(result => {
                if ('name' in result) {
                    setUser(result);

                    let a = new Date();
                    a.getDate();
                    let b = new Date(user.registrationDate);
                    let c = Math.floor((a - b) / 1000 / 3600 / 24)
                    setDays(c);
                }
                else {
                    history('/sign_in');
                }
            }
            )
            .catch(error => console.log('error', error));

    }
    useEffect(load, []);

    let block = useRef();
    let block1 = useRef();

    function sendPhone(e) {
        e.preventDefault();

        const forms = document.getElementById('form')

        if (!forms.checkValidity()) {
            e.stopPropagation()
            forms.classList.add('was-validated')
            return
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.token);

        var raw = JSON.stringify(user);

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://pets.сделай.site/api/users/phone", requestOptions)
            .then(response => response.status)
            .then(result => {
                if (result == 200) {
                    let message = 'Изменение прошло успешно';
                    block.current.innerText = message;
                    block.current.style.background = "#D6D6FF"
                    block.current.style.color = "black";
                    block.current.style.border = "1px solid rgb(119, 119, 255)"
                    block.current.style.display = 'block';
                }
            })
            .catch(error => console.log('error', error));
    }

    function sendEmail(e) {
        e.preventDefault();

        const forms = document.getElementById('formTwo')

        if (!forms.checkValidity()) {
            e.stopPropagation()
            forms.classList.add('was-validated')
            return
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.token);
        var raw = JSON.stringify(user);

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://pets.сделай.site/api/users/email", requestOptions)
            .then(response => response.status)
            .then(result => {
                if (result == 200) {
                    let message = 'Изменение прошло успешно';
                    block1.current.innerText = message;
                    block1.current.style.background = "#D6D6FF"
                    block1.current.style.color = "black";
                    block1.current.style.border = "1px solid rgb(119, 119, 255)"
                    block1.current.style.display = 'block';
                }
            })
            .catch(error => console.log('error', error));
    }

    function exit() {
        localStorage.clear();
        history('/sign_in');
    }

    return (
        <div>
            <div className="d-flex flex-column mb-4 align-items-center">
                <div className="card w-50 m-4 border border-primary" style={{ "width": "18rem" }}>
                    <div className="card-body">
                        <h5 className="card-title">Информация о пользователе</h5>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item p-3">ФИО: {user.name}</li>
                        <li className="list-group-item">
                            <div className="d-flex flex-raw justify-content-between align-items-center">
                                Телефон: {user.phone}
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Редактировать
                                </button>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex flex-raw justify-content-between align-items-center">
                                Почта: {user.email}
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalTwo">
                                    Редактировать
                                </button>
                            </div>
                        </li>
                        <li className="list-group-item p-3">Количество дней на сайте: {days}</li>
                    </ul>
                </div>
                <button type="button" className="btn btn-danger" style={{ "width": "10%" }} data-bs-toggle="modal" data-bs-target="#exit">Выйти</button>
            </div>


            <div className="modal anim_mess" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Редактировать номер телефона</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="needs-validation flex justify-content-center" noValidate id='form' onSubmit={sendPhone}>
                                <div>
                                    <input type="text" pattern='^[\d\+]{12}$' className="form-control" required onChange={(e) => setUser({ ...user, phone: e.target.value })} />
                                    <div className="invalid-feedback anim_mess" style={{ "margin": "15px 0 15px 0" }}>
                                        Введите номер телефона, используйте + и цифры
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                                    <button className="btn btn-primary" type="submit">Редактировать</button>
                                </div>
                            </form>
                            <div className="alert alert-primary none anim_mess" role="alert" ref={block}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal anim_mess" id="exampleModalTwo" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Редактировать email</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="needs-validation flex justify-content-center" noValidate id='formTwo' onSubmit={sendEmail}>
                                <div>
                                    <input type="email" className="form-control" required onChange={(e) => setUser({ ...user, email: e.target.value })} />
                                    <div className="invalid-feedback anim_mess" style={{ "margin": "15px 0 15px 0" }}>
                                        Укажите корректный email
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                                    <button className="btn btn-primary" type="submit">Редактировать</button>
                                </div>
                            </form>
                            <div className="alert alert-primary none anim_mess" role="alert" ref={block1}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal anim_mess" id="exit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Вы действительно хотите выйти?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body d-flex justify-content-center">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={exit}>Выйти</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Form;