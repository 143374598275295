import React, { useRef, useState } from 'react';
import '../styles/main.css';
import Header from './header';
import Footer from './footer';

const Registration = () => {
	let [user, setUser] = useState({password:''});
	let block = useRef();

	function send(e) {
		e.preventDefault();

		const forms = document.getElementById('form')

		if (!forms.checkValidity()) {
			e.stopPropagation()
			forms.classList.add('was-validated')
			return
		}

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify(user);

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		fetch("https://pets.сделай.site/api/register", requestOptions)
			.then(response => response.status)
			.then(result => {
				if (result == 204) {
				
					let message = 'Вы успешно зарегистрировались!';
					block.current.innerText = message;
					block.current.style.background = "#D6D6FF"
					block.current.style.color = "black";
					block.current.style.border = "1px solid rgb(119, 119, 255)"
					block.current.style.display = 'block';
				}
		/*		else {
					let message = 'Проверьте правильность ввода данных';
					block.current.innerText = message;
					block.current.style.background = '#FFC6C2';
					block.current.style.color = "black";
					block.current.style.border = "1px solid #FF968F"
					block.current.style.display = 'block';
				}*/
			})
			.catch(error => console.log('error', error));
	}
	return (
		<div>
			<Header />
			<div className='main'>
				<h1 className="title">Регистрация</h1>
				<form className="needs-validation w-50 flex justify-content-center" noValidate style={{ "margin": "30px auto 0 auto", "minWidth" : "300px"}} onSubmit={send} id='form'>
					<div>
						<label htmlFor="validationCustom01" className="form-label">Имя</label>
						<input type="text" pattern='^[А-Яа-яёЁ\s\-]+$' className="form-control" id="validationCustom01" required onChange={(e) => setUser({ ...user, name: e.target.value })} />
						<div className="invalid-feedback anim_mess">
							Введите имя, используйте кириллицу, пробел, тире
						</div>
					</div>
					<div style={{ "margin": "15px auto 0px auto" }}>
						<label htmlFor="validationCustom02" className="form-label">Телефон</label>
						<input type="text" pattern='^[\d\+]{12}$' className="form-control" id="validationCustom02" required onChange={(e) => setUser({ ...user, phone: e.target.value })} />
						<div className="invalid-feedback anim_mess">
							Введите номер телефона, используйте + и цифры
						</div>
					</div>
					<div style={{ "margin": "15px auto 15px auto" }}>
						<label htmlFor="validationCustom03" className="form-label">Email</label>
						<input type="email" className="form-control" id="validationCustom03" required onChange={(e) => setUser({ ...user, email: e.target.value })} />
						<div className="invalid-feedback anim_mess">
							Укажите корректный email
						</div>
					</div>
					<div style={{ "margin": "15px auto 15px auto" }}>
						<label htmlFor="validationCustom04" className="form-label">Пароль</label>
						<input type="password" pattern='^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d]{7,}$' className="form-control" id="validationCustom04" required onChange={(e) => setUser({ ...user, password: e.target.value })}/>
						<div className="invalid-feedback anim_mess">
							Введите пароль, латинские буквы обязательно одна заглавная, строчная и цифра, минимум 7 символов
						</div>
					</div>
					<div style={{ "margin": "15px auto 15px auto" }}>
						<label htmlFor="validationCustom05" className="form-label">Введите пароль повторно</label>
						<input type="password"  pattern={user.password} className="form-control" id="validationCustom05" required onChange={(e) => setUser({ ...user, password_confirmation: e.target.value })} />
						<div className="invalid-feedback anim_mess">
							Введите пароль повторно, пароли должны совпадать
						</div>
					</div>
					<div>
						<div className="form-check" style={{ "margin": "15px auto 15px auto" }}>
							<input className="form-check-input" type="checkbox" id="invalidCheck" required onClick={(e) => setUser({ ...user, confirm: e.target.checked})}/>
							<label className="form-check-label" htmlFor="invalidCheck">
								Я принимаю оферту и соглашаюсь на обработку персональных данных
							</label>
							<div className="invalid-feedback anim_mess">
								Вы должны принять перед отправкой
							</div>
						</div>
					</div>
					<div>
						<button className="btn btn-primary" style = {{"marginBottom": "30px"}} type="submit">Зарегистрироваться</button>
					</div>
				</form>
				<div className="alert alert-primary w-50 none anim_mess" role="alert" ref={block}></div>
			</div>
			<Footer />


		</div>
	);
};

export default Registration;