import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";

const CardPage = () => {
    const location = useLocation();
    const [card, setCard] = useState([]);

    const request = () => {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`https://pets.сделай.site/api/pets/${location.state}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(location.state);
                console.log(result);
                if ('data' in result) {
                    setCard(result.data.pet);
                }

            })
            .catch(error => console.log('error', error));
    }
    useEffect(request, []);

    return (
        <div>
            <Header />
            <div style={{ "minHeight": "75vh" }} >
                <div className="cards" >
                    <div className="card post" style={{ "width": "18rem" }}>
                        <img src={'https://pets.сделай.site' + card.photos1} className="card-img-top imageCard" alt="Картинка" />
                        <div className="card-body d-flex flex-column justify-content-start">
                            <h5>{card.name}</h5>
                            <p className="card-text">Потерялся: {card.kind}</p>
                            <p className="card-text">Описание: {card.description}</p>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Район: {card.district}</li>
                            <li className="list-group-item">Код: {card.mark}</li>
                            <li className="list-group-item">Район: {card.district}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CardPage;