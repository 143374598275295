import {Routes, Route} from 'react-router-dom';
import Main from "./components/main";
import Search from "./components/search";
import Signup from './components/registration';
import Signin from "./components/signin";
import Account from './components/account';
import AddAnnouncement from "./components/addAnnouncement";
import CardPage from './components/cardPage';

function App() {
  return (
    <div>
      <Routes>
        <Route path = {'/'} element = {<Main/>}/>
        <Route path = {'/search'} element = {<Search/>}/>
        <Route path = {'/registration'} element = {<Signup/>}/>
        <Route path = {'/sign_in'} element = {<Signin/>}/>
        <Route path = {'/account'} element = {<Account/>}/>
        <Route path = {'/add_announcement'} element = {<AddAnnouncement/>}/>
        <Route path = {'/cardPage'} element = {<CardPage/>}/>
      </Routes>
    </div>
  );
}

export default App;